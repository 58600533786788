<template>
    <div>
        <img v-if="item.typeEntity == 'B'" title="Basic" src="@/assets/images/icons/basic.png" alt="sello"
            class="superior-derecha" />
        <img v-else-if="item.typeEntity == 'P'" title="Premium" src="@/assets/images/icons/premium.png" alt="sello"
            class="superior-derecha" />
        <img v-else src="@/assets/images/icons/member.png" title="Member" alt="sello" class="superior-derecha" />
        <img v-show="item.coparmex === 1" title="Coparmex" src="@/assets/images/icons/coparmex.png" alt="sello1"
            class="superior-derecha-segunda" />
        <img v-show="item.prevented === 1" title="Different disabilities" src="@/assets/images/icons/prevented.jpeg"
            alt="sello1" class="superior-derecha-tercera" />
        <img v-if="item.repsabi === 1" src="@/assets/images/icons/repssabi.png" alt="sello1"
            class="superior-derecha-cuarta" title="Repssabi" />
    </div>
</template>
<script>
export default {
    name: "TypeEntityDiscView",
    props: {
        item: {
            type: Object,
            required: true,
        }
    }
}
</script>
<style>
.superior-derecha {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 80px;
    height: 80px;
}

.superior-derecha-segunda {
    position: absolute;
    top: 73px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-tercera {
    position: absolute;
    top: 145px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-cuarta {
    position: absolute;
    top: 230px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}
</style>
