<template>
    <v-col cols="6" md="4">
        <v-row class="p-custom-search">
            <v-col cols="6">
                <v-select dense v-model="province" :items="provinces" item-text="name" item-value="id"
                    :loading="loadingData || loading" label="State" clearable>
                </v-select>
            </v-col>
            <v-col cols="6">
                <v-select dense v-model="municipality" :items="municipalities" item-text="name" item-value="id"
                    :disabled="!province" :loading="loadingData || loading" label="Municipality" clearable>
                </v-select>
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
import service from "@/providers/CitiesService";

export default {
    name: "FilterStateMunicipality",
    props: {
        loadingData: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            province: null,
            municipality: null,
            provinces: [],
            municipalities: [],
            loading: true,
            dialog: false,
        }
    },
    methods: {
        loadData() {
            this.loading = true;
            service.getAllRecords().then((resp) => {
                this.provinces = resp.value;
                this.searchValueDefault();
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                this.loading = false;
            });
        },
        searchValueDefault(){
            const state = this.provinces.find(e=>e.name == "Baja California");
            if(state){
                this.province = state.id
                const m = state.municipalities.find(e=>e.name == "Tijuana");
                if(m){
                    this.municipality = m.id;
                }
            }
            this.$emit('filter-data', {
                province: this.province??"",
                municipality: this.municipality??"",
            });
        },
        openDialog(){
            this.dialog = true;
        },
        search(){
            this.$emit('filter-data', {
                province: this.province,
                municipality: this.municipality,
            });
            this.dialog = false;
        }
    },
    watch: {
        province(value) {
            let name = null;
            if(value){
                const p = this.provinces.find(e => e.id === value);
                name = p.name;
                this.municipalities = p.municipalities;
            }
            //this.municipality = null;
            this.$emit('filter-data', {
                province: value,
                municipality: "",
            });
            this.$emit('filter-data-name', {
                province: name,
                municipality: null,
            });
            return value;
        },
        municipality(value){
            this.$emit('filter-data', {
                province: this.province,
                municipality: value,
            });
            this.$emit('filter-data-name', {
                province: this.provinces.find(e => e.id === this.province).name,
                municipality: value?this.municipalities.find(e=>e.id === value).name:null,
            });
            return value;
        }
    },
    mounted() {
        this.loadData();
    },
}
</script>
<style>
.p-custom-search {
    padding-top: 6px !important;
}
</style>
